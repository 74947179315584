// import React, { useState, useEffect } from "react";
// import { loginapi2 } from "../routes/api"; // Import your API function
// import "bootstrap/dist/css/bootstrap.min.css";
// import Main from "./main.js";
// import TextField from "@mui/material/TextField";
// import "../style/Login.css";

// const Login_page = () => {
//   const windowHeight = window.innerHeight;
//   const [userType, setUserType] = useState("");
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [isButtonDisabled, setIsButtonDisabled] = useState(true);
//   const [errorMessage, setErrorMessage] = useState("");
//   const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status

//   const handleEmailChange = (e) => {
//     setEmail(e.target.value);
//     setIsButtonDisabled(!e.target.value);
//     setErrorMessage("");
//   };

//   const handlePasswordChange = (e) => {
//     setPassword(e.target.value);
//     setErrorMessage("");
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       console.log("Sending login request with:", email, password);
//       const loginResponse = await loginapi2(email, password);
//       console.log("Received login response:", loginResponse);

//       if (loginResponse && loginResponse.message === "Login successful") {
//         setIsLoggedIn(true);
//         setUserType(loginResponse.user_type);
//       } else {
//         setErrorMessage("Invalid email or password");
//       }
//     } catch (error) {
//       console.error("API request failed:", error);
//       setErrorMessage("Invalid email or password");
//     }
//   };

//   useEffect(() => {
//     if (isLoggedIn) {
//       // Perform successful login logic here
//       console.log("Successful login");

//       // Set a timeout to redirect to login after 1 hour (3600000 milliseconds)
//       const timeoutId = setTimeout(() => {
//         setIsLoggedIn(false); // Set login status to false after 1 hour
//       }, 3600000);

//       // Clear the timeout if the component unmounts or user logs out manually
//       return () => clearTimeout(timeoutId);
//     }
//   }, [isLoggedIn]);

//   console.log(userType);

//   if (isLoggedIn) {
//     return (
//       <div>
//         <Main userType={userType} login_status={setIsLoggedIn} />
//       </div>
//     );
//   }

//   return (
//     <div
//       className="bg-dark d-flex justify-content-center align-items-center login-container"
//       style={{ height: windowHeight }}
//     >
//       <div
//         className="bg-white d-flex flex-column m-3 justify-content-center align-items-center p-3 rounded"
//         style={{ boxShadow: "2px solid black" }}
//       >
//         <img
//           src={`${process.env.PUBLIC_URL}/icons/logo.png`}
//           alt="Logo"
//           className="img-fluid p-2"
//           style={{ width: "15vh", alignSelf: "center" }}
//         />
//         <h4 className="text-center" style={{ fontFamily: "Comic Sans MS" }}>
//           LMH Campus{" "}
//         </h4>

//         <form onSubmit={handleSubmit} className="form1">
//           <TextField
//             className="m-2"
//             id="outlined"
//             type="email"
//             color="success"
//             label="Email"
//             value={email}
//             onChange={handleEmailChange}
//           />

//           <TextField
//             className="m-2"
//             id="outlined"
//             type="password"
//             label="Password"
//             color="success"
//             value={password}
//             onChange={handlePasswordChange}
//           />

//           <button
//             type="submit"
//             className="btn mb-3 p-2 text-white"
//             style={{ backgroundColor: "#6c82ff" }}
//           >
//             Log In
//           </button>
//           {errorMessage && (
//             <div className="error-container">
//               <p className="error-message">{errorMessage}</p>
//             </div>
//           )}
//           <p className="h6 mt-2" style={{ fontFamily: "Comic Sans MS" }}>
//             Energy Monitoring System
//           </p>
//           <img
//             src={`${process.env.PUBLIC_URL}/icons/log.png`}
//             alt="Logo Right"
//             className="img-fluid"
//             style={{ width: "15vh", alignSelf: "center" }}
//           />
//         </form>
//       </div>
//       <div
//         className="sticky-text text-white d-flex justify-content-center align-items-center"
//         style={{ height: "45px", backgroundColor: "#6c82ff" }}
//       >
//         <p className="p-0 m-0">
//           {" "}
//           © 2024 Copyright: conceptualized, Designed, Installed & Maintained By{" "}
//           <strong>Heta Datain</strong>
//         </p>
//       </div>
//     </div>
//   );
// };

// export default Login_page;
import React, { useState, useEffect } from "react";
import { loginapi2 } from "../routes/api"; // Import your API function
import "bootstrap/dist/css/bootstrap.min.css";
import Main from "./main.js";
import TextField from "@mui/material/TextField";
import "../style/Login.css";

const Login_page = () => {
  const windowHeight = window.innerHeight;
  const [userType, setUserType] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status
  const [showModal, setShowModal] = useState(false); // To handle modal visibility

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsButtonDisabled(!e.target.value);
    setErrorMessage("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrorMessage("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log("Sending login request with:", email, password);
      const loginResponse = await loginapi2(email, password);
      console.log("Received login response:", loginResponse);

      if (loginResponse && loginResponse.message === "Login successful") {
        setIsLoggedIn(true);
        setUserType(loginResponse.user_type);
      } else {
        setErrorMessage("Invalid email or password");
        setShowModal(true); // Show modal on login failure
      }
    } catch (error) {
      console.error("API request failed:", error);
      setErrorMessage("Invalid email or password");
      setShowModal(true); // Show modal on error
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      // Perform successful login logic here
      console.log("Successful login");

      // Set a timeout to redirect to login after 1 hour (3600000 milliseconds)
      const timeoutId = setTimeout(() => {
        setIsLoggedIn(false); // Set login status to false after 1 hour
      }, 3600000);

      // Clear the timeout if the component unmounts or user logs out manually
      return () => clearTimeout(timeoutId);
    }
  }, [isLoggedIn]);

  console.log(userType);

  if (isLoggedIn) {
    return (
      <div>
        <Main userType={userType} login_status={setIsLoggedIn} />
      </div>
    );
  }

  return (
    <div
      className="bg-dark d-flex justify-content-center align-items-center login-container"
      style={{ height: windowHeight }}
    >
      <div
        className="bg-white d-flex flex-column m-3 justify-content-center align-items-center p-3 rounded"
        style={{ boxShadow: "2px solid black" }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/icons/logo.png`}
          alt="Logo"
          className="img-fluid p-2"
          style={{ width: "15vh", alignSelf: "center" }}
        />
        <h4 className="text-center" style={{ fontFamily: "Comic Sans MS" }}>
          LMH Campus{" "}
        </h4>

        <form onSubmit={handleSubmit} className="form1">
          <TextField
            className="m-2"
            id="outlined"
            type="email"
            color="success"
            label="Email"
            value={email}
            onChange={handleEmailChange}
          />

          <TextField
            className="m-2"
            id="outlined"
            type="password"
            label="Password"
            color="success"
            value={password}
            onChange={handlePasswordChange}
          />

          <button
            type="submit"
            className="btn mb-3 p-2 text-white"
            style={{ backgroundColor: "#6c82ff" }}
          >
            Log In
          </button>
          <p className="h6 mt-2" style={{ fontFamily: "Comic Sans MS" }}>
            Energy Monitoring System
          </p>
          <img
            src={`${process.env.PUBLIC_URL}/icons/log.png`}
            alt="Logo Right"
            className="img-fluid"
            style={{ width: "15vh", alignSelf: "center" }}
          />
        </form>
      </div>

      {/* Modal for login error */}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{
          display: showModal ? "block" : "none",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ maxWidth: "350px", margin: "auto" }} // Adjust width
        >
          <div
            className="modal-content"
            style={{
              boxShadow:
                "0 8px 16px rgba(0, 0, 0, 0.4), 0 12px 40px rgba(0, 0, 0, 0.6)", // Increased shadow
              padding: "20px", // Add some padding inside the box
              maxHeight: "250px", // Adjust height
            }}
          >
            <div className="modal-header justify-content-center">
              <h5 className="modal-title">Login Error</h5>
              <button
                type="button"
                className="close position-absolute"
                style={{ right: "10px", top: "10px" }}
                onClick={() => setShowModal(false)}
              >
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <p>Invalid email or password</p>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn"
                onClick={() => setShowModal(false)}
                style={{ backgroundColor: "#6c82ff", color: "white" }}
              >
                Try Again
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="sticky-text text-white d-flex justify-content-center align-items-center"
        style={{ height: "45px", backgroundColor: "#cccc" }}
      >
        <p className="p-0 m-0">
          {" "}
          © 2024 Copyright: conceptualized, Designed, Installed & Maintained By{" "}
          <strong>Heta Datain</strong>
        </p>
      </div>
    </div>
  );
};

export default Login_page;
