import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "../style/pie.css"; // Assuming styles are in Home.css

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ data }) => {
  // Prepare the chart data based on the fetched data
  const chartData = {
    labels: data.map((item) => item.device_name), // Use device_name as labels
    datasets: [
      {
        data: data.map(() => Math.random() * 100), // Replace with actual values if needed
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"], // You may want to customize colors
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
        borderColor: "#fff",
        borderWidth: 2,
        hoverOffset: 10,
      },
    ],
  };

  const options = {
    cutout: "70%",
    rotation: -90,
    plugins: {
      legend: {
        position: "right",
      },
      tooltip: {
        backgroundColor: "#000",
        titleColor: "#fff",
      },
    },
  };

  return (
    <div className="pie-chart-container">
      <h2 className="pie-chart-title">Incomer Devices Chart</h2>
      <div className="pie-chart">
        <Pie data={chartData} options={options} />
      </div>
    </div>
  );
};

export default PieChart;
