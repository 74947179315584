// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
import logo from "./logo.svg";
import "./App.css";
import React from "react";
import Main from "./components/main";
import SortName from "./components/SortName";
import Login from "./components/login";
import StudentReportDownloader from "./components/StudentReportDownloader";
import Logout from "./components/logout";
import BI_reports from "./components/bi_reports";
import Piechart from "./components/pie";
import DatePickerComponent from "./components/DatePickerComponent";

function App() {
  return (
    <div className="App">
      {/* <StudentReportDownloader /> */}
      {/* <SortName /> */}
      {/* <Main /> */}
      {/* <BI_reports /> */}
      <Login />
      {/* <home /> */}
      {/* <Piechart /> */}
      {/* <DatePickerComponent /> */}
    </div>
  );
}

export default App;
